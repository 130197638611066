<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <div class="actions-tab actions-tab-budget-forms">
                <budget-attach-file/>
                <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret ref="dropdown" @shown="adjustDropdownMenuStyle">
                    <template #button-content>
                        <div class="btn-download" :disabled="isReportUploading">
                            <div>
                <span class="left">
                    <i class="icon icon-download"></i> Скачать
                </span>
                                <i class="icon icon-keyboard"></i>
                            </div>
                        </div>
                    </template>
                    <b-dropdown-item @click="onDownloadRep(false)">Отчет 02-159 (дороги)</b-dropdown-item>
                    <b-dropdown-item @click="downloadRep69">Приложение 69</b-dropdown-item>
                    <b-dropdown-item @click="downloadRepRF">Распределение трансфертов_рабочая</b-dropdown-item>
                    <b-dropdown-item @click="onDownloadRep(true, true)" :disabled="$store.state.isDownloadButtonEnabled">
                        {{$store.state.isDownloadButtonEnabled ? $t("modules.budget.budget_request.uploadIsProgress") : $t("modules.budget.budget_request.batchUploadGuButton") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="onDownloadRep(true, false)" :disabled="$store.state.isDownloadButtonEnabled" v-if="guListLen > 1">
                        {{$store.state.isDownloadButtonEnabled ? $t("modules.budget.budget_request.uploadIsProgress") : $t("modules.budget.budget_request.batchUploadAbpButton") }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <b-tabs v-model="tabIndex">
            <div class="filter-container">
                <div class="left-content">
                    <budget-header v-show="tabIndex === 0" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                </div>
                <div class="right-content">
                    <div class="filter-actions filter-actions-flex">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetFormToCopy"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button variant="success" @click="prepareSave" :disabled="tabIndex === 0 ? isLoadProject || !variantAttribute : isLoad || !variantAttribute">Сохранить</b-button>
                    </div>
                </div>
            </div>

            <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                                :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
            <b-progress
                    variant="success"
                    v-show="progress < 100 && progress > 0"
                    height="10px"
                    :value="progress"
                    striped
                    animated
            ></b-progress>
            <b-tab title="Проекты" active>
                <div class="table-container">
                    <b-table
                        :fields="prFields"
                        :items="prForm"
                        responsive="true"
                        bordered
                        head-variant="light"
                        sticky-header="true"
                        no-border-collapse
                    >
                        <template #top-row="data">
                            <td class="td-numbering table-success">1</td>
                            <td class="td-numbering table-danger">2</td>
                            <td class="td-numbering table-info">3</td>
                            <td class="td-numbering table-primary">4</td>
                            <td class="td-numbering table-warning">5</td>
                            <td class="td-numbering table-success">6</td>
                            <td class="td-numbering table-danger">7</td>
                            <td class="td-numbering"></td>
                        </template>
                        <template #head(more)>
                            <template v-if="variantAttribute">
                                <b-button :title="messages.addRegion"
                                    style="color:green" @click="addRegion(prForm, true)">
                                    <i class="icon icon-plus-circle-fill"/>
                                </b-button>
                            </template>
                        </template>
                        <template #cell(name_ru)="data">
                            <template v-if="data.item.src_region && variantAttribute">
<!--                                <b-form-select size="sm"-->
<!--                                               class="w-200px h-auto pre-wrap"-->
<!--                                               v-model="data.item.region"-->
<!--                                               :options="listRegPr"-->
<!--                                >-->
<!--                                </b-form-select>-->

                                <div
                                    class="column-width-100"
                                    :class="{ 'error': data.item.checkForDuplicatesReg }">
                                    <b-form-select v-if="variantAttribute"
                                                   size="sm"
                                                   class="w-200px h-auto pre-wrap"
                                                   v-model="data.item.region"
                                                   :options="listRegPr"
                                                   @change="onChangeReg(data.item.region, data, true)"
                                    >
                                    </b-form-select>
                                    <div v-else>{{ data.item.region.text }}</div>
                                    <template v-if="data.item.checkForDuplicatesReg">
                                        <i class="icon icon-danger"></i>
                                        <div class="pop-up">
                                            <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                            <p>Такая запись уже в базе существует</p>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="data.item.src_region && !variantAttribute">
                                <div>
                                    {{ data.item.region.name_ru }}
                                </div>
                            </template>
                            <template v-else-if="variantAttribute && !isProjectHasSpendings(data.item)">

                                <div
                                    class="column-width-100"
                                    :class="{ 'error': data.item.checkForDuplicates }">
                                    <b-form-textarea v-if="variantAttribute"
                                                     :title="data.item.name_ru"
                                                     :value="data.item.name_ru"
                                                     @change="checkDoubles($event, data.item)"
                                                     @keyup.enter.exact="keyup13"
                                                     max-rows="15"
                                                     class="resize-auto-hidden"
                                    >
                                    </b-form-textarea>
                                    <div v-else>{{ data.item.name_ru }}</div>
                                    <template v-if="data.item.checkForDuplicates">
                                        <i class="icon icon-danger"></i>
                                        <div class="pop-up">
                                            <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                            <p>Такая запись уже в базе существует</p>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    {{ data.item.name_ru }}
                                </div>
                            </template>
                        </template>
                        <template #cell(unit)="data">
                            <b-form-select v-if="!data.item.src_region && variantAttribute && !data.item.hasSpendings"
                                           size="sm"
                                           class="w-200px h-auto pre-wrap"
                                           v-model="data.item.unit"
                                           :options="listUnit"
                                           @change="data.item.unit_code = data.item.unit.code"
                            >
                            </b-form-select>
                            <div v-else-if="!data.item.src_region">
                                {{ data.item.unit['national_symbol'] }}
                            </div>
                        </template>
                        <template #cell(distance)="data">
                            <template v-if="!data.item.src_region && data.item.unit !== null && variantAttribute && !data.item.hasSpendings">
                                <div :class="{ 'error': ['001.006.008','002.055'].includes(data.item.unit.code) && (data.item.distance === 0)}">
                                    <b-form-input class="text-right"
                                                  :value="data.item.distance"
                                                  @change="v => data.item.distance = v"
                                                  @keyup.enter.exact="keyup13"
                                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                  @blur="inputFixedVldtn(data.item, 'distance', data.item.distance, 3)">
                                    </b-form-input>
                                    <i class="icon icon-danger" v-if="['001.006.008','002.055'].includes(data.item.unit.code) && (data.item.distance === 0)"></i>
                                    <div class="pop-up" v-if="['001.006.008','002.055'].includes(data.item.unit.code) && (data.item.distance === 0)">
                                        <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                        <p>Если ед.измер=км или м2, то поле протяженность &ne; 0</p>
                                    </div>
                                </div>
                            </template>
                            <div v-else-if="!data.item.src_region && data.item.unit !== null">
                                {{ data.item.distance }}
                            </div>
                        </template>
                        <template #cell(population)="data">
                            <b-form-input v-if="!data.item.src_region && variantAttribute && !data.item.hasSpendings"
                                          class="text-right"
                                          :value="data.item.population"
                                          @change="v => data.item.population = v"
                                          @keyup.enter.exact="keyup13"
                                          @keypress="keyPress($event, '^[0-9]+$')"
                                          @blur="inputFixedVldtn(data.item, 'population', data.item.population, 0)">
                            </b-form-input>
                            <div v-else-if="!data.item.src_region">
                                {{ data.item.population }}
                            </div>
                        </template>
                        <template #cell(beg_date)="data">
                            <template v-if="!data.item.src_region && variantAttribute && !data.item.hasSpendings">
                                <div :class="{'error': data.item.beg_date >= data.item.end_date}">
                                    <c-date-picker 
                                        :item="data.item" 
                                        :header="header"
                                        dateField="beg_date"
                                        startEnd="start"
                                    ></c-date-picker>
                                    <i class="icon icon-danger" v-if="data.item.beg_date && data.item.beg_date >= data.item.end_date"></i>
                                    <div class="pop-up" v-if="data.item.beg_date >= data.item.end_date">
                                        <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                        <p>Дата начала &lt; Дата окончания</p>
                                    </div>
                                </div>
                            </template>
                            <div v-else-if="!data.item.src_region">
                                {{ data.item.beg_date }}
                            </div>
                        </template>
                        <template #cell(end_date)="data">
                            <c-date-picker 
                                v-if="!data.item.src_region && variantAttribute && !data.item.hasSpendings"
                                :item="data.item" 
                                :header="header"
                                dateField="end_date"
                                startEnd="end"
                            ></c-date-picker>
                            <div v-else-if="!data.item.src_region">
                                {{ data.item.end_date }}
                            </div>
                        </template>
                        <template #cell(more)="data">
                            <template v-if="variantAttribute">
                                <b-button style="color:green"
                                          v-if="data.item.src_region && data.item.region !== null"
                                          @click="addProject(data.item, data.index)"
                                          :title="messages.addProject">
                                    <i class="icon icon-plus-circle-fill"/> 
                                </b-button>
                                <template v-if="!data.item.src_region">
                                    <b-button v-if="!data.item.hasSpendings" @click="deletePr(data.item, data.index)">
                                        <i class="icon icon-close"></i>
                                    </b-button>
                                    <b-button v-else  @click="onShowSpndings(data.item)">
                                        <i title="Имеются расходы по проекту" 
                                        class="icon icon-info"></i>
                                    </b-button>
                                </template>
                            </template>
                        </template>
                    </b-table>
                </div>
                <c-spendings-modal :header="header" ref="spendingsModal"/>
            </b-tab>
            <b-tab title="Форма ввода">
                <div class="table-container">
                    <b-table
                        :fields="tableFields"
                        :items="budgetForm"
                        responsive="true"
                        bordered
                        head-variant="light"
                        sticky-header="true"
                        no-border-collapse
                    >
                        <template #top-row="data">
                            <td class="td-numbering"></td>
                            <td class="td-numbering table-success">1</td>
                            <td class="td-numbering table-danger">2</td>
                            <td class="td-numbering table-info">3</td>
                            <td class="td-numbering table-primary">4</td>
                            <td class="td-numbering table-warning">5</td>
                            <td class="td-numbering table-success">6</td>
                            <td class="td-numbering table-danger">7</td>
                            <td class="td-numbering table-primary">8</td>
                            <td class="td-numbering table-warning">9</td>
                            <td class="td-numbering"></td>
                        </template>
                        <template #head(more)>
                            <b-button v-if="variantAttribute" 
                                      style="color:green" 
                                      @click="addRegion(budgetForm)"
                                      :title="messages.addRegion">
                                <i class="icon icon-plus-circle-fill"/>
                            </b-button>
                        </template>
                        <template #cell(action)="data">
                            <template v-if="data.item.src_region && variantAttribute">
                                <b-button title="Удалить регион и все его проекты" 
                                          @click="deleteRegion(data.item, data.index)">
                                    <i class="icon icon-close"></i>
                                </b-button>
                            </template>
                        </template>
                        <template #cell(name_ru)="data">
                            <template v-if="data.item.src_region">
<!--                                <b-form-select v-if="variantAttribute"-->
<!--                                               size="sm"-->
<!--                                               class="w-200px h-auto pre-wrap"-->
<!--                                               v-model="data.item.region"-->
<!--                                               :options="listReg"-->
<!--                                               @change="regProjects(data.item)"-->
<!--                                >-->
<!--                                </b-form-select>-->
<!--                                <div v-else>{{ data.item.region.text }}</div>-->

                                <div
                                    class="column-width-100"
                                    :class="{ 'error': data.item.checkForDuplicatesReg }">
                                    <b-form-select v-if="variantAttribute"
                                                   size="sm"
                                                   class="w-200px h-auto pre-wrap"
                                                   v-model="data.item.region"
                                                   :options="listReg"
                                                   @change="onChangeReg(data.item.region, data, false)"
                                    >
                                    </b-form-select>
                                    <div v-else>{{ data.item.region.text }}</div>
                                    <template v-if="data.item.checkForDuplicatesReg">
                                        <i class="icon icon-danger"></i>
                                        <div class="pop-up">
                                            <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                            <p>Такая запись уже в базе существует</p>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
<!--                                <b-form-select v-if="variantAttribute"-->
<!--                                               size="sm"-->
<!--                                               class="w-200px h-auto pre-wrap"-->
<!--                                               v-model="data.item.name_ru"-->
<!--                                               :options="data.item.projects"-->
<!--                                               @change="defProject(data.item)"-->
<!--                                >-->
<!--                                </b-form-select>-->
<!--                                <div v-else>{{ data.item.name_ru.name_ru }}</div>-->

                                <div
                                    class="column-width-100"
                                    :class="{ 'error': data.item.checkForDuplicates }">
                                    <b-form-select v-if="variantAttribute"
                                                   size="sm"
                                                   class="w-200px h-auto pre-wrap"
                                                   v-model="data.item.name_ru"
                                                   :options="data.item.projects"
                                                   @change="onChangeDef(data.item.name_ru, data, data.item.region)"
                                    >
                                    </b-form-select>
                                    <div v-else>{{ data.item.name_ru.name_ru }}</div>
                                    <template v-if="data.item.checkForDuplicates">
                                        <i class="icon icon-danger"></i>
                                        <div class="pop-up">
                                            <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                            <p>Такая запись уже в базе существует</p>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </template>
                        <template #cell(unit)="data">
                            <div v-if="!data.item.src_region && data.item.unit !== null">{{ data.item.unit.national_symbol }}</div>
                        </template>
                        <template #cell(region)="data">
                            <b-form-select v-if="data.item.src_region && variantAttribute"
                                           size="sm"
                                           class="w-200px h-auto pre-wrap"
                                           v-model="data.item.region"
                                           :options="listReg"
                            >
                            </b-form-select>
                            <div v-else-if="!variantAttribute">{{ data.value }}</div>
                        </template>
                        <template #cell(area)="data">
                            <b-form-input v-if="!data.item.src_region && variantAttribute"
                                          :value="data.item.area"
                                          @change="v => data.item.area = v"
                                          @keyup.enter.exact="keyup13">
                            </b-form-input>
                            <div v-else-if="!variantAttribute">{{ data.value }}</div>
                        </template>
                        <template #cell(distance)="data">
                            <div class="text-right">{{ data.item.distance }}</div>
                        </template>
                        <template #cell(population)="data">
                            <div class="text-right">{{ data.item.population }}</div>
                        </template>
                        <template #cell(cost_amount)="data">
                            <template v-if="!data.item.src_region && variantAttribute">
                                <div :class="{ 'error': data.item.cost_amount === 0}">
                                    <b-form-input
                                        class="text-right"
                                        :value="data.item.cost_amount"
                                        @change="v => data.item.cost_amount = v"
                                        @keyup.enter.exact="keyup13"
                                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                        @blur="inputFixedVldtn(data.item, 'cost_amount', data.item.cost_amount, 3)">
                                    </b-form-input>
                                    <i class="icon icon-danger" v-if="data.item.cost_amount === 0"></i>
                                    <div class="pop-up" v-if="data.item.cost_amount === 0">
                                        <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                        <p>Сумма расходов &ne; 0</p>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="!variantAttribute">
                                <div>{{ data.value }}</div>
                            </template>
                        </template>
                        <template #cell(program)="data">
                            <b-form-select v-if="!data.item.src_region && variantAttribute"
                                           size="sm"
                                           class="w-200px h-auto pre-wrap"
                                           v-model="data.item.program"
                                           :options="listProgram"
                            >
                            </b-form-select>
                            <div v-else-if="!variantAttribute">{{ data.value.name_ru }}</div>
                        </template>
                        <template #cell(note)="data">
                            <textarea v-if="!data.item.src_region && variantAttribute"
                                      style="height: 100%"
                                      cols="50"
                                      v-model="data.item.note"
                                      @keyup.enter.exact="keyup13">
                            </textarea>
                            <div v-else-if="!variantAttribute">{{ data.value }}</div>
                        </template>
                        <template #cell(files)="data">
                            <span
                                v-if="!data.item.src_region"
                                class="blue pointer underline"
                                @click="openModalRowFilesByRowId(data.item.id, false)"
                            >({{data.item.files}})</span>
                        </template>
                        <template #cell(more)="data">
                            <template v-if="variantAttribute">
                                <b-button v-if="data.item.src_region" 
                                          style="color:green" 
                                          @click="addItem(data.item, data.index)"
                                          :title="messages.addProject">
                                    <i class="icon icon-plus-circle-fill"/>
                                </b-button>

                                <b-dropdown v-if="variantAttribute && !data.item.src_region" id="dropdown-dropleft" dropleft class="more">
                                    <template v-slot:button-content>
                                        <i class="icon icon-more"></i>
                                    </template>
                                    <template>
                                        <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                            Добавить файл(ы)
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteItem(data.item, data.index)">
                                            Удалить
                                        </b-dropdown-item>
                                    </template>
                                </b-dropdown>
                            </template>
                        </template>

                        <template #bottom-row="data">
                            <td class="text-right" colspan="4">ИТОГО</td>
                            <td class="text-right">{{ $n(distance) }}</td>
                            <td class="text-right">{{ $n(population) }}</td>
                            <td class="text-right">{{ $n(total) }}</td>
                            <td></td>
                        </template>
                    </b-table>
                </div>
                <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                              :load="load"
                              @getFiles="getFiles"
                              @getNewFiles="getNewFiles($event)"
                              @delFile="delFile($event)"
                              ref="fileUpdown"
                ></files-updown>
                <modal-attach ref="modalAttach"
                              :row-files-input="row_files"
                              :header="header"
                              :is-add="isAdd"
                              :variant-attribute="variantAttribute"
                              @toggleIsAdd="toggleIsAdd($event)"
                              @fileUpload="fileUpload"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import store from '../../services/store';
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import BudgetAttachFile from "./components/budget-attach-file";
import CDatePicker from "./components/c-date-picker.vue";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import CSpendingsModal from './components/c-spendings-modal.vue'

export default {
    name: 'Form02_159_1',
    components: { BudgetHeader, 
                FilesUpdown, 
                BudgetFormsList, 
                BreadcrumbsFilter, 
                ModalAttach, 
                BudgetAttachFile, 
                CDatePicker, 
                CBudgetFormsCopyData,
                CSpendingsModal 
                },
    mixins: [FormsHandlerMixin1],
    watch: {
        prForm: {
            handler: function () {
                this.dasableRegions(this.listRegPr, this.prForm)
            },
            deep: true
        },
            
        budgetForm: {
            handler: function () {
                this.dasableRegions(this.listReg, this.budgetForm)
            },
            deep: true
        },
    },

    data() {
        return {
            form: {
                code: '02-159_1',
                name_ru: 'РАСЧЕТ РАСХОДОВ ПО ОПЛАТЕ РАБОТ И УСЛУГ, ОКАЗАННЫХ ФИЗИЧЕСКИМИ ЛИЦАМИ, ГОСУДАРСТВЕННЫМИ ПРЕДПРИЯТИЯМИ И ОРГАНИЗАЦИЯМ С ГОСУДАРСТВЕННЫМ УЧАСТИЕМ',
                name: 'Форма 02-159 (Дороги)',
                name_kk: 'Жеке тұлғалар және акцияларының бақылау пакеттері мемлекетке тиесілі мемлекеттік кәсіпорындар, акционерлік қоғамдар және мемлекеттік қатысу үлестерінің мөлшерлері қатысушылардың жалпы жиналысының шешімін анықтауға мүмкіндік беретін жауапкершілігі шектеулі серіктестіктер көрсеткен жұмыстарға және көрсетілетін қызметтерге төлеу жүргізу бойынша шығыстарды есептеу',
                spf: {
                    id: 36,
                    kat: 1,
                    cls: 1,
                    pcl: 150,
                    spf: 159,
                    name_ru: 'Оплата прочих услуг и работ',
                    name_kk: null,
                    type: 4,
                    full_code: '11150159'
                }
            },
            header: null,
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'code',
                    label: 'Код проекта'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование района/проекта'
                },
                {
                    key: 'unit',
                    label: 'Единица измерения'
                },
                {
                    key: 'distance',
                    label: 'Протяженность'
                },
                {
                    key: 'population',
                    label: 'Охват населения (человек)'
                },
                {
                    key: 'cost_amount',
                    label: 'Сумма расходов (тыс.тенге)'
                },
                {
                    key: 'program',
                    label: 'Финансирование из госпрограммы'
                },
                {
                    key: 'note',
                    label: 'Примечание'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            budgetForm: [],
            tree: [],
            prFields: [
                {
                    key: 'code',
                    label: 'Код проекта'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование района/проекта'
                },
                {
                    key: 'unit',
                    label: 'Единица измерения'
                },
                {
                    key: 'distance',
                    label: 'Протяженность'
                },
                {
                    key: 'population',
                    label: 'Охват населения (человек)'
                },
                {
                    key: 'beg_date',
                    label: 'дата начала'
                },
                {
                    key: 'end_date',
                    label: 'дата окончания'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            prForm: [],
            prTree: [],
            listReg: [],
            listRegPr: [],
            listUnit: [],
            listProgram: [],
            listProject: [],
            files: null,
            load: false,
            isLoadProject: false,
            isLoad: false,
            openDisabled: false,
            projects: null,
            filter: [
                { funcGr: 12,
                    abp: [{
                        gr: 12,
                        pgr: 1,
                        abp: 268,
                        'name_ru': 'Управление пассажирского транспорта и автомобильных дорог области',
                        'full_code': '26800',
                        type: 3,
                        'name_kk': 'Облыстың жолаушылар көлігі және автомобиль жолдары басқармасы',
                        'budget_level_id': 2,
                        progr: [{ 'id': 38914, 'gr': 12, 'pgr': 1, 'abp': 268, 'prg': 25, 'ppr': null, 'type': 4, 'name_ru': 'Капитальный и средний ремонт автомобильных дорог областного значения и улиц населенных пунктов', 'name_kk': 'Облыстық автомобиль жолдарын және елді-мекендердің көшелерін күрделі және орташа жөндеу', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268250', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '25 - Капитальный и средний ремонт автомобильных дорог областного значения и улиц населенных пунктов' },
                            { 'id': 38919, 'gr': 12, 'pgr': 1, 'abp': 268, 'prg': 28, 'ppr': null, 'type': 4, 'name_ru': 'Реализация приоритетных проектов транспортной инфраструктуры', 'name_kk': 'Көлiк инфрақұрылымының басым жобаларын іске асыру', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268280', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '28 - Реализация приоритетных проектов транспортной инфраструктуры' },
                            { 'id': 39279, 'gr': 12, 'pgr': 9, 'abp': 268, 'prg': 113, 'ppr': null, 'type': 4, 'name_ru': 'Целевые текущие трансферты нижестоящим бюджетам', 'name_kk': 'Төменгі тұрған бюджеттерге берілетін нысаналы ағымдағы трансферттер', 'short_name_ru': null, 'short_name_kk': null, 'beg_date': null, 'end_date': null, 'full_code': '268280', 'develop_type': 0, 'transfer': null, 'is_sequest': null, 'budget_level_id': null, 'name': '113 - Целевые текущие трансферты нижестоящим бюджетам' }
                        ]
                    }] }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            tabIndex: 0,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: 0,
        };
    },
    async mounted() {
        await this.getObl();
        await this.loadUnits();
        await this.loadPrograms();
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                if (!item.src_region) {
                    let num_attach_files = 0;
                    item['row_files'] = [...item['row_files'], ...files];
                    item['row_files'].forEach((file) => {
                        if (file.row_id === item['id']) {
                            num_attach_files += 1;
                        }
                    });
                    item['files'] = num_attach_files;
                }
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                if (!item.src_region) {
                    item['row_files'].forEach((file, index) => {
                        if (file.file_id === fileId) {
                            item['row_files'].splice(index, 1);
                        }
                    });
                    item.files = item['row_files'].filter(i => i.row_id !== null).length;
                }
            });
            this.$refs.modalAttach.delFile(fileId);
        },

        async addItem(region, index) {
            const item = { id: this.newRowStartId };
            this.newRowStartId--;

            this.$set(item, 'region', region.region.code);
            this.$set(item, 'projects', region.projects);
            this.$set(item, 'unit', null);
            for (const pr of item.projects) {
                this.$set(pr, 'disabled', false);
                for (const row of this.budgetForm) {
                    if (!row.src_region) {
                        if (pr.value.code === row.code) {
                            this.$set(pr, 'disabled', true);
                        }
                    }
                }
            }
            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;

            this.budgetForm.splice(index + 1, 0, item);
        },

        addProject(region, index) {
            const item = {};
            this.$set(item, 'id', 0);
            this.$set(item, 'region', region.region.code);
            this.$set(item, 'code', null);
            this.$set(item, 'name_ru', null);
            this.$set(item, 'unit', null);
            this.$set(item, 'unit_code', null);
            this.$set(item, 'distance', 0);
            this.$set(item, 'population', 0);
            this.$set(item, 'beg_date', null);
            this.$set(item, 'end_date', null);

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);
            this.prForm.splice(index + 1, 0, item);
            this.checkDoubles();
        },

        addRegion(list, isProject = false) {
            const item = {
                src_region: true,
                region: null,
                child: []
            };

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicatesReg', false);
            list.push(item);
            this.checkDoublesReg(isProject);
        },

        async loadSpecificData() {
            await this.loadRegions();
            await this.listReg.splice(0);
            for (const reg of this.listRegPr) {
                this.listReg.push(Object.assign({}, reg));
            }
            await this.loadProjects();
        },

        createTable(elem, region, table) {
            const item = Object.assign({}, elem);
            this.$set(item, 'visible', true);

            if (item.src_region) {
                this.$set(item, 'open', true);
                this.$set(item, 'hasChild', true);
            }
            this.$set(item, 'index', table.length);
            this.$set(table, table.length, item);

            if (item.hasChild) {
                for (const ch of item.child) {
                    this.createTable(ch, item, table);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        checkDoubles(newVal, item) {
            if (newVal !== undefined) item.name_ru = newVal.trim()
            const firstEntryItm = {};
            this.prForm.forEach((itm, idx) => {
                if (!itm.src_region) {
                    const regionCode = itm.region ? itm.region : 'no code';   
                    const prjName = itm.name_ru ? itm.name_ru : 'no name'; 
                    if (!firstEntryItm.hasOwnProperty(regionCode)) {
                        firstEntryItm[regionCode] = {};
                        firstEntryItm[regionCode][prjName] = idx;
                        itm.checkForDuplicates = false;
                    } else {
                        if (!firstEntryItm[regionCode].hasOwnProperty(prjName)) {
                            firstEntryItm[regionCode][prjName] = idx;
                            itm.checkForDuplicates = false;
                        } else {                        
                            itm.checkForDuplicates = true;
                            const firsDoubleIdx = firstEntryItm[regionCode][prjName];
                            this.prForm[firsDoubleIdx].checkForDuplicates = true;
                        };
                    };
                };
            });
        },

        afterDeleted() {
            this.checkDoubles();
        },

        onChangeDef(value, data, region) {
            this.budgetForm.some((item, i) => {
                if (!item.src_region) {
                    if (i !== data.index) {
                        if (item.region === region && item.name_ru.name_ru === value.name_ru) {
                            data.item.checkForDuplicates = true;

                            // если схожий по наименованию запись найдено то выходит из функции
                            return true;
                        } else {
                            data.item.checkForDuplicates = false;
                        }
                    }
                }
            });
            this.defProject(data.item);
        },

        defProject(item) {
            this.$set(item, 'code', item.name_ru.code);
            this.$set(item, 'unit', item.name_ru.unit);
            this.$set(item, 'distance', item.name_ru.distance);
            this.$set(item, 'population', item.name_ru.population);
            this.$set(item, 'cost_amount', 0);
            this.$set(item, 'program', null);
            this.$set(item, 'note', null);
        }, // определение проекта

        async delete(item) {
            if (Array.isArray(item)) {
                item.forEach(itm => this.$set(itm, 'form', this.form.code))
            } else [this.$set(item, 'form', this.form.code)];
            try {
                this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'cost_amount'));
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                    this.loadProjects();
                    this.loadDataWithoutPpr(this.filterData);
                    // await this.saveTotal();
                }
            } catch (error) {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных ' + error);
            } finally {
                this.checkDoublesReg(false);
            }
        }, // удаление данных

        deleteRegion(row, index) {
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление региона и всех вложенных проектов...',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        const itemsToDel = []
                        for (let i = (this.budgetForm.length - 1); i>=0; i--) {
                            const ch = this.budgetForm[i];
                            if (!ch.src_region) {
                                const chRegionCode = typeof ch.region === 'string' ? ch.region : ch.region?.code;
                                if (chRegionCode === row.region?.code) {
                                    this.budgetForm.splice(i, 1);
                                    if (ch.id > 0) {
                                        itemsToDel.push(ch)
                                    }
                                }
                            }   
                        }
                        if (itemsToDel.length) this.delete(itemsToDel);
                        this.budgetForm.splice(index, 1);
                        this.checkDoublesReg(false);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаляет элемент из формы ввода

        deletePr(row, index) {
            this.$bvModal.msgBoxConfirm(
                'Данные с данным проектом будут также удалены. ' +
                'Подтвердите удаление проекта...',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.prForm.splice(index, 1);
                        if (row.id > 0) {
                            this.delPr(row);
                        } else {
                            this.checkDoubles();
                            this.checkDoublesReg(true);
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                })
        }, // удаляет элемент из формы ввода

        async delPr(item) {
            this.$set(item, 'form', this.form.code);
            try {
                const response = await fetch('/api-py/delete-budget-request-project/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if (response.status === 200) {
                    await this.loadProjects();
                    this.loadDatas();
                    this.makeToast('success', 'Сообщение', 'Проект удален');
                }
            } catch (error) {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных ' + error);
            } finally {
                this.checkDoubles();
                this.checkDoublesReg(true);
            }
        }, // удаление данных

        deleteItem(row, index) {
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление данной записи...',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.budgetForm.splice(index, 1);
                        if (row.id > 0) {
                            this.delete(row);
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // удаляет элемент из формы ввода

        onDownloadRep(batch = false, oneGu = false) {
            this.checkSignatories(batch, oneGu);
            if (batch) {
                this.downloadBatchReports(oneGu);
            } else {
                this.downloadRep();
            }
        },

        downloadRep69() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            Ax(
                {
                    url: '/api-py/rep_02_159_69/' + JSON.stringify(this.header),
                    method: 'POST',
                    data: this.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Приложение 69.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.error = error;
                    this.isReportUploading = false;
                }
            );
        },
        downloadRepRF() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            Ax(
                {
                    url: '/api-py/rep_02_159_rf/' + JSON.stringify(this.header),
                    method: 'POST',
                    data: this.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Распределение трансфертов_рабочая.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.error = error;
                    this.isReportUploading = false;
                }
            );
        },

        getFiles(data) {
            this.files = data;
        }, // список файлов

        getItem(code, list) {
            for (const item of list) {
                if (item.value.code === code) {
                    return item.value;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.region = json.region;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$'); только целые числа
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            await this.tree.splice(0);
            this.loadDataWithoutPpr(this.filterData);

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                const result = await response.json();
                values = result.filter(itm => this.listProject.findIndex(prj => prj.value && prj.value.code && prj.value.code === itm.code_project) !== -1)
                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicatesReg равен true
                const listCheckDuplicates = [];
                const listCheckDuplicatesReg = [];

                await values.forEach(val => {
                    const item = val;

                    this.$set(item, 'name_ru', this.getItem(val.code_project, this.listProject));
                    this.$set(item, 'region', this.getItem(item.name_ru.region, this.listReg));
                    this.$set(item, 'program', this.getItem(val.code_program, this.listProgram));
                    this.$set(item, 'code', item.name_ru.code);
                    this.$set(item, 'unit', item.name_ru.unit);
                    this.$set(item, 'distance', item.name_ru.distance);
                    this.$set(item, 'population', item.name_ru.population);
                    item['checkForDuplicates'] = !!listCheckDuplicates
                        .find(el => el.get(item['region']['code']) === item.name_ru.name_ru);
                    // listCheckDuplicates.push(item.name_ru.name_ru)
                    const pushItem = new Map();
                    pushItem.set(item['region']['code'], item.name_ru.name_ru);
                    listCheckDuplicates.push(pushItem);

                    this.$set(item, 'files', val.files);
                    this.$set(item, 'row_files', val.row_files);

                    const region = this.tree.filter(function (row) {
                        if (row.region.code === item.region.code) { return row; }
                    });
                    if (region.length > 0) {
                        this.$set(item, 'projects', region[0].projects);
                        region[0].child.push(item);
                    } else {
                        const regItem = {
                            src_region: true,
                            region: item.region,
                            child: []
                        };
                        regItem['checkForDuplicatesReg'] = !!listCheckDuplicatesReg.find(el => el === item.region.code)
                        this.regProjects(item);
                        regItem.child.push(item);
                        this.regProjects(regItem);
                        this.tree.push(regItem);

                        listCheckDuplicatesReg.push(item.region.code)
                    }
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
                return;
            }

            for (const item of this.tree) {
                this.createTable(item, null, this.budgetForm);
            }
            this.load = false;
            this.getDataToCopy();
        }, // загрузка данных

        async loadUnits() {
            const codes = ['001.006.008', '008.642', '002.055'];
            try {
                const response = await fetch('/api-py/dictionary_code/unit/' + codes);
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.national_symbol);
                    this.listUnit.push(el);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник единиц измерения

        async loadProjects() {
            await this.prForm.splice(0);
            await this.prTree.splice(0);
            await this.listProject.slice(0);
            try {
                const response = await fetch('/api-py/get-projects/' + JSON.stringify(this.header));
                const result = await response.json();
                const values = this.filterDistricts(result, 'region');
                // в этот массив записывается все наименование записей, потом по этому массиву идет проверка
                // если в него есть массив то атрибут checkForDuplicatesReg равен true
                const listCheckDuplicates = [];
                const listCheckDuplicatesReg = [];

                for (const row of values) {
                    // формируем дерево проектов
                    this.$set(row, 'unit', this.getItem(row.unit_code, this.listUnit));

                    // список проектов
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);

                    row['checkForDuplicates'] = !!listCheckDuplicates
                        .find(e => e.get(row['region']) === row.name_ru);
                    const pushItem = new Map();
                    pushItem.set(row['region'], row.name_ru);
                    listCheckDuplicates.push(pushItem);

                    this.listProject.push(el);

                    // формируем дерево проектов
                    const region = this.prTree.filter(function (item) {
                        if (item.region.code === row.region) { return item; }
                    });
                    if (region.length > 0) {
                        region[0].child.push(row);
                    } else {
                        const reg = this.getItem(row.region, this.listReg);
                        if (reg !== null) {
                            const item = {
                                src_region: true,
                                region: Object.assign({}, reg),
                                child: []
                            };
                            item['checkForDuplicatesReg'] = !!listCheckDuplicatesReg.find(el => el === row.region.code)
                            item.child.push(row);
                            this.prTree.push(item);

                            listCheckDuplicatesReg.push(item.region.code)
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadProjects', error.toString());
                return;
            }

            for (const item of this.prTree) {
                this.createTable(item, null, this.prForm);
            }
        }, // загрузка проектов

        async loadPrograms() {
            try {
                const response = await fetch('/api-py/dict_program/');
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    this.listProgram.push(el);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник единиц измерения

        checkDoublesReg(isProject) {
            let list = null;
            if (isProject) {
                list = this.prForm;
            } else {
                list = this.budgetForm;
            }
            const firstEntryItm = {}
            list.forEach((itm, idx) => {
                if (itm.src_region) {
                    const code = itm.region?.code === undefined || itm.region.code === null ? "no code" : itm.region.code
                    if (!firstEntryItm.hasOwnProperty(code)) {
                        firstEntryItm[code] = idx;
                        itm.checkForDuplicatesReg = false;
                    } else {
                        itm.checkForDuplicatesReg = true;
                        const firsDoubleIdx = firstEntryItm[code];
                        list[firsDoubleIdx].checkForDuplicatesReg = true;
                    }
                };
            });
        },

        onChangeReg(value, data, isProject) {
            this.hasRegionProjects(data.item)
            this.checkDoublesReg(isProject);
            this.regProjects(data.item);
        },

        regProjects(parent) {
            const region = this.prTree.filter(function (item) {
                if (item.region.code === parent.region.code) { return item; }
            });

            if (region.length > 0) {
                const list = [];
                // const now = new Date().toLocaleDateString().split('.');
                // const cd = new Date(`${this.header.year}-${now[1]}-${now[0]}`);
                for (const row of region[0].child) {
                    // if (((row.beg_date === null) || (cd >= new Date(row.beg_date)))
                    //     && ((row.end_date === null) || (cd <= new Date(row.end_date)))) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    list.push(el);
                    // }
                }
                this.$set(parent, 'projects', list);
            } else {
                delete parent.projects;
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        numRRD(region) {
            let num = 0;
            for (const row of this.prjForm) {
                if (!row.src_region && (region === row.region)) {
                    num++;
                }
            }
            return ++num;
        }, // генерация кода проекта

        prepareSave() {
            if (this.tabIndex === 0) {
                this.projectForSave();
            } else {
                this.prepareForSave();
            }
        },

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    if (row.cost_amount > 0) {
                        const item = Object.assign({}, this.header);
                        this.$set(item, 'id', row.id);
                        this.$set(item, 'code_project', row.code);
                        this.$set(item, 'cost_amount', parseFloat(row.cost_amount));
                        this.$set(item, 'code_program', (row.program !== null ? row.program.code : null));
                        this.$set(item, 'note', row.note);
                        this.$set(item, 'row_files', row.row_files);

                        values.push(item);
                    } else {
                        error = true;
                    }
                }
            }
            // if (values.length > 0 && this.variantAttribute) {
            //     this.save(values, error);
            // } else {
            //     this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            // }

            if (this.budgetForm.find(item => item.checkForDuplicatesReg === true || item.checkForDuplicates === true)) {
                this.makeToast('danger', 'Предупреждение', 'Данные не соответствуют контролю. Данные не сохранены!');
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values, error);
                }
                else {
                    this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
                }
            }
        }, // подготовка массива данных для сохранения

        projectForSave() {
            const values = [];
            const error = false;
            let rowNumber = 1
            for (const row of this.prForm) {
                if (!row.src_region) {
                    if (!this.isDataValid(row, rowNumber)) return;

                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'code', row.code);
                    this.$set(item, 'region', row.region);
                    this.$set(item, 'name_ru', row.name_ru.trim());
                    this.$set(item, 'unit_code', row.unit.code);
                    this.$set(item, 'distance', row.distance);
                    this.$set(item, 'population', row.population);
                    this.$set(item, 'beg_date', row.beg_date);
                    this.$set(item, 'end_date', row.end_date);
                    values.push(item);
                }
                ++rowNumber;
            }
            if (values.length && this.variantAttribute) {
                this.savePr(values, error);
            }
            else {
                this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            }
            
        }, // подготовка проектов для сохранения

        async save(values, error) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    if (error) {
                        this.makeToast('warning', 'Сообщение', 'Ошибочно введенные данные не были сохранены');
                    } else {
                        this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    }
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async savePr(values, error) {
            try {
                this.isLoadProject = true;
                const response = await fetch('/api-py/save-budget-request-project/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (error) {
                        this.makeToast('warning', 'Сообщение', 'Ошибочно введенные данные не были сохранены');
                    } else {
                        this.makeToast('success', 'Сообщение', 'Проекты сохранены');
                    }
                    this.loadDatas();
                    await this.loadProjects();
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения проектов');
            } finally {
                this.isLoadProject = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        }, // сохранение итога

        
    },
    computed: {
        distance() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    sum += row.distance ? parseFloat(row.distance) : 0;
                }
            }
            return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
        },
        population() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    sum += row.population ? parseFloat(row.population) : 0;
                }
            }
            return sum;
        },
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_region) {
                    sum += row.cost_amount ? parseFloat(row.cost_amount) : 0;
                }
            }
            return Math.ceil(sum);
        }
    }
};
</script>

<style scoped>
.actions-tab-budget-forms .b-dropdown .dropdown-menu {
    /* transform: translate3d(-180px, 30px, 0px) !important; */
    left: -75px !important;
}

.filter-actions-flex {
    display: flex;
}
</style>